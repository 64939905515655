import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";
import GutenbergBlocks from "../components/gutenberg-blocks";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import Breadcrumbs from "../components/breadcrumbs";

const HERO_BLOCKS = ["core/cover", "mev/hero", "mev/superhero", "core/columns"];

const Page = ({
  data: {
    wpPage: { blocks, isFrontPage, excerpt, title, seo },
    wp,
  },
}) => {
  const HEADING_H1_BLOCK =
    blocks?.[0]?.name === "core/heading" &&
    blocks?.[0]?.attributes?.level === 1;
  return (
    <Layout>
      <SEO
        title={seo?.title || title}
        description={seo?.metaDesc || excerpt}
        breadcrumbs={seo?.breadcrumbs}
      />
      {!isFrontPage && (
        <Container maxWidth="md" disableAnimation disableMargin>
          {wp.seo?.breadcrumbs?.enabled && (
            <Breadcrumbs breadcrumbList={seo?.breadcrumbs} />
          )}
          {!HERO_BLOCKS.includes(blocks?.[0]?.name) && !HEADING_H1_BLOCK && (
            <h1 style={{ textAlign: "left" }}>{title}</h1>
          )}
        </Container>
      )}
      <GutenbergBlocks blocks={blocks} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object,
};

export default Page;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      blocks {
        ...GutenbergBlocks
      }
      title
      excerpt
      uri
      isFrontPage
      seo {
        title
        breadcrumbs {
          text
          url
        }
        metaDesc
      }
    }
    wp {
      seo {
        breadcrumbs {
          enabled
        }
      }
    }
  }
`;
